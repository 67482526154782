import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { StorageTools } from 'src/app/shared/tools/storage.tools';
import { HomeModels } from '../home-models/home.models';
import { HomeService } from '../home-services/home-services';
import { SampleBoxDataProvider } from '../../shared/services/sampleBoxDataProvider.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalSelectFaenaComponent } from '../modal-select-faena/modal-select-faena.component';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Renderer2 } from '@angular/core';
import { MatModalConfirmComponent } from 'src/app/shared/components/mat-dialog-content/mat-dialog-content.component';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
    current_nav_url = ""

    constructor(
        public authService: AuthenticationService,
        private router: Router,
        private homeService: HomeService,
        public spinner: SpinnerService,
        private appData: SampleBoxDataProvider,
        public dialog: MatDialog,
        private overlayContainer: OverlayContainer,
        private renderer: Renderer2
    ) {         
    }

    ngOnInit(): void {
        let returnUrl = localStorage.getItem("returnUrl");
        console.log(returnUrl);
        let error_sample_login_client = false;
        let jsonObject: any = null;
        if (returnUrl?.toLocaleLowerCase().includes("reports2")) {
            // Paso 1: Decodificar la cadena base64 a una cadena normal
            let decodedString = atob(returnUrl.split("/")[2]);

            // Paso 2: Convertir la cadena decodificada a un objeto JSON
            jsonObject = JSON.parse(decodedString);

            console.log(jsonObject.clienteId);
            console.log(jsonObject.faenaId);
            console.log(jsonObject.muestraId);

            if (jsonObject && this.authService.currentUserValue.clientes.find(c => c.clienteId === jsonObject.clienteId) && this.authService.currentUserValue.clientes.find(c => c.clienteId === jsonObject.clienteId)?.faenas.find(f => f.faenaId === jsonObject.faenaId)) {
                returnUrl = `/reports2/${jsonObject.muestraId}`;
            } else {       
                error_sample_login_client = true;        
                const dialogRef = this.dialog.open(MatModalConfirmComponent, {
                    panelClass: 'ds-modal',
                    disableClose: true,
                    data: {
                        title: "Visualizar Muestra",
                        msg: "La muestra que deseas visualizar no corresponde a un cliente o faena que tengas asignado."
                    }
                });
    
                dialogRef.afterClosed().subscribe(() => {
                    if (returnUrl){                                
                        window.location.href = '/home';
                        localStorage.removeItem("returnUrl");
    
                    }
                });                
            }
        }        

        if (!error_sample_login_client && (this.authService.currentClientValue.clienteId === '' || this.authService.currentClientValue.faena.faenaId === '')) {
            const dialogRef = this.dialog.open(ModalSelectFaenaComponent, {
                panelClass: 'ds-modal',
                disableClose: true,
                data: {
                    param: jsonObject
                }
            });

            dialogRef.afterOpened().subscribe(() => {
                const backdrop = this.overlayContainer.getContainerElement().querySelector('.cdk-overlay-backdrop');
                this.renderer.addClass(backdrop, 'ds-modal-backdrop');
            });

            dialogRef.afterClosed().subscribe(() => {
                if (returnUrl){                                
                    window.location.href = returnUrl;
                    localStorage.removeItem("returnUrl");
                }
            });
        } else if(!error_sample_login_client) {
            if (returnUrl){            
                window.location.href = returnUrl;
                localStorage.removeItem("returnUrl");
            }
        } else{
            // nada aqui aun
        }
    }

    logOut(): void {
        this.authService.logout();
    }

    irAbusquedaComponente() {
        this.router.navigate(['/busqueda-componente']);
    }

    openTableau(): void {
        window.location.href = '/external-content';
    }

    cambioClienteFaena(): void {
        const dialogRef = this.dialog.open(ModalSelectFaenaComponent, {
          panelClass: 'ds-modal',
        });

        dialogRef.afterOpened().subscribe(() => {
          const backdrop = this.overlayContainer.getContainerElement().querySelector('.cdk-overlay-backdrop');
          this.renderer.addClass(backdrop, 'ds-modal-backdrop');
        });
      }
}
