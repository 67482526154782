import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import es from '@angular/common/locales/es';

import { CommonModule, APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { JwtInterceptor } from './shared/auth/jwt.interceptor';
import { ErrorInterceptor } from './shared/auth/error.interceptor';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StorageTools } from './shared/tools/storage.tools';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { LoginComponent } from './login/login-view/login.component';
import { HomeComponent } from './home/home-view/home.component';
import { Tools } from './shared';
import { Pkce } from './shared/tools/pkce.tools';
import { OauthCallbackComponent } from './oauth-callback/oauth-callback-view/oauth.callback.component';
import { BusquedaCompomenteComponent } from './ingreso-muestra/busqueda-componente/busqueda-views/busqueda.component';
import { RegistraMuestraComponent } from './ingreso-muestra/registra-muestra/registra-views/registra.component';
import { ComprobanteMuestraComponent } from './ingreso-muestra/comprobante-muestra/comprobante-views/comprobante.component';
import { RegistraMuestraMultipleComponent } from './ingreso-muestra/registro-muestra-multiple/registro-multiple-views/registro-multiple.component';
import { CajaDeMuestrasComponent } from './caja-muestras/caja-muestras-views/caja-view/caja-muestas.component';
import { MatModalConfirmComponent } from './shared/components/mat-dialog-content/mat-dialog-content.component';
import { NgModalConfirmComponent } from './shared/components/ng-modal-confirm/ng-modal-confirm-views/ng-modal-confirm.component';
import { EditarMuestraComponent } from './ingreso-muestra/editar-muestra/editar-views/editar-muestra.component';
import { ToastsContainer } from './shared/components/ng-toast/toasts-container.component';
import { ComprobanteCajaMuestraComponent } from './caja-muestras/caja-muestras-views/comprobante-view/caja-comprobante.component';
import { CajaDeMuestrasIconComponent } from './caja-muestras/caja-muestras-views/icon-view/caja-muestas-icon.component';
import { CartaAutRetiroComponent } from './shared/components/carta-autorizacion-retiro/carta-views/carta.component';
import { TarjetaComprobanteEnvioComponent } from './caja-muestras/caja-muestras-views/tarjeta-envio-view/tarjeta.component';
import { MantSolicitudDeAnalisisComponent } from './mantenedores/mant-solicitud-de-analisis/mant-solicitud-analisis-views/mantenedor-view/mant-solicitud-analisis.component';
import { SolicitudDeAnalisisComponent } from './shared/components/doc-solicitud-de-analisis/solicitud-views/solicitud.component';
import { ModalSolicitudAnalisisComponent } from './shared/components/modal-solicitud-analisis/modal-solicitud-views/modal-solicitud.component';
import { PopoverEditarTransporteComponent } from './mantenedores/mant-solicitud-de-analisis/mant-solicitud-analisis-views/popover-editar-transp-view/editar-transporte.component';
import { SolicitudDeInsumosComponent } from './solicitud-de-insumos/solicitud-de-insumos-views/table-view/solicitud-de-insumos.component';
import { ModalSolicitudDeInsumosComponent } from './solicitud-de-insumos/solicitud-de-insumos-views/modal-solicitud-view/modal-solicitud-component';
import { CondicionesComercialesComponent } from './condiciones-comerciales/condiciones-comerciales-views/condiciones-comerciales.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { NavMenuComponent } from './components/nav-menu/nav-menu-view/nav-menu.component';
import { NavHeaderComponent } from './components/nav-header/nav-header-view/nav-header.component';
import { FooterComponent } from './components/footer/footer-view/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MomentModule } from 'ngx-moment';
import { NgxKjuaModule } from 'ngx-kjua';


import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatCheckboxModule } from '@angular/material/checkbox';



import { NumericDirective } from './shared/directives/numeric.directive';
import { MatSpinnerOverlayComponent } from './shared/components/mat-spinner-overlay/mat-spinner-overlay.component';
import { LoadingInterceptor } from './shared/interceptors/loading.interceptor';
import { UnicoNumeroEnvaseValidatorDirective } from './shared/directives/unicoNumeroEnvase.directive';
import { UnicoNumeroEnvaseRegistradoValidatorDirective } from './shared/directives/unicoNumeroEnvaseRegistrado.directive';
import { MantEquiposComponent } from './mantenedores/mant-equipos/mant-equipos-views/mantenedor/mant-equipos.component';
import { MantComponentesComponent } from './mantenedores/mant-componentes/mant-componentes-views/mantenedor/mant-componentes.component';
import { ComponenteAsignarEquipoComponent } from './mantenedores/mant-componentes/mant-componentes-views/comp-asignar-equipo/comp-asignar-equipo-views/comp-asignar-equipo.component';
import { ComponenteVerComponent } from './mantenedores/mant-componentes/mant-componentes-views/componente-ver/componente-ver-views/componente-ver.component';
import { ComponenteNuevoComponent } from './mantenedores/mant-componentes/mant-componentes-views/componente-nuevo/componente-nuevo-views/componente-nuevo.component';
import { EquipoNuevoComponent } from './mantenedores/mant-equipos/mant-equipos-views/equipo-nuevo/equipo-nuevo-views/equipo-nuevo.component';
import { EquipoVerComponent } from './mantenedores/mant-equipos/mant-equipos-views/equipo-ver/equipo-ver-views/equipo-ver.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BlockSpecialCharactersDirective } from './shared/directives/blockSpecialCharacters.directive';
import { BlockEmailSpecialCharactersDirective } from './shared/directives/blockEmailSpecialCharacters.directive';
import { UsuarioNuevoComponent } from './mantenedores/mant-usuarios/mant-usuarios-views/usuario-nuevo/usuario-nuevo-views/usuario-nuevo.component';
import { UsuarioPerfilComponent } from './mantenedores/mant-usuarios/mant-usuarios-views/usuario-perfil/usuario-perfil-views/usuario-perfil.component';
import { MantUsuariosComponent } from './mantenedores/mant-usuarios/mant-usuarios-views/mantenedor/mant-usuarios.component';
import { PhoneDirective } from './shared/directives/phoneDirective.directive';
import { NameDirective } from './shared/directives/nameDirective.directive';
import { ValidacionRutDirective } from './shared/directives/validacionRut.directive';
import { AlphaNumericCharactersDirective } from './shared/directives/alphaNumericCharacters.directive';
import { IdDirective } from './shared/directives/idDirective.directive';
import { IdComponente } from './shared/directives/idComponente.directive';

import { CustomPaginator } from './shared/components/custom-pagination/custom-pagination';
import { ValidaMuestraIdDirective } from './shared/directives/validacionMuestraId.directive';
import { MantMuestrasComponent } from './mantenedores/mant-muestras/mant-muestras.component';
import { ModalGalleryComponent } from './shared/components/modal-gallery/modal-gallery.component';
import { PaginatorComponent } from './shared/components/paginator/paginator.component';
import { ExternalContentComponent } from './external-content/view/ext.component';
import { ReportsModule } from './modules/reports2/reports.module';
import { BasicLayoutModule } from './modules/layouts/basic-layout/basic-layout.module';
import { ValidarFechaDirective } from './shared/directives/validar-fecha.directive';
import { ReporteMenuComponent } from './reportes/reporte-menu/reporte-menu.component';
import { ReporteHistoricoComponent } from './reportes/reporte-historico/reporte-historico.component';
import { ModalReportesComponent } from './reportes/modal-reportes/modal-reportes.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { ModalSelectFaenaComponent } from './home/modal-select-faena/modal-select-faena.component';
import { GetFaenasByClientIdPipe } from './shared/pipes/get-faenas-by-client-id.pipe';
import { ReporteComponentesComponent } from './reportes/reporte-componentes/reporte-componentes.component';

import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { LocalStorageOAuthStorage } from './shared/auth/local-storage-oauth-storage';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RegistroModalComentarioComponent } from './ingreso-muestra/registro-muestra-multiple/registro-modal-comentario/registro-modal-comentario.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { HtmlTooltipDirective } from './shared/directives/html-tooltip-directive.directive';
import { PendientesFacturacionComponent } from './condiciones-comerciales/condiciones-comerciales-components/pendientes-facturacion/pendientes-facturacion.component';
import { P } from '@angular/cdk/keycodes';
import { ModalDocumentDetailComponent } from './condiciones-comerciales/condiciones-comerciales-components/pendientes-facturacion/pendientes-facturacion-components/modal-document-detail/modal-document-detail.component';
import { ProformaModule } from './condiciones-comerciales/condiciones-comerciales-components/pendientes-facturacion/pendientes-facturacion-components/proforma/proforma.module';
import { CacheInterceptor } from './shared/interceptors/cache.interceptor';
import { SampleDetailComponent } from './sample/sample-detail/sample-detail.component';
import { EditSampleComponent } from './sample/edit-sample/edit-sample.component';
import { ModalEditSampleComponent } from './sample/modal-edit-sample/modal-edit-sample.component';
import { MoverComponentComponent } from './mantenedores/mant-componentes/mant-componentes-views/mover-component/mover-component.component';

export function playerFactory() {
    return player;
}


registerLocaleData(es);
@NgModule({
    declarations: [
        AppComponent,
        NumericDirective,
        ValidarFechaDirective,
        UnicoNumeroEnvaseValidatorDirective,
        UnicoNumeroEnvaseRegistradoValidatorDirective,
        BlockSpecialCharactersDirective,
        BlockEmailSpecialCharactersDirective,
        PhoneDirective,
        NameDirective,
        ValidacionRutDirective,
        ValidaMuestraIdDirective,
        AlphaNumericCharactersDirective,
        IdDirective,
        IdComponente,
        LoginComponent,
        HomeComponent,
        OauthCallbackComponent,
        NavMenuComponent,
        NavHeaderComponent,
        FooterComponent,
        BusquedaCompomenteComponent,
        RegistraMuestraComponent,
        EditarMuestraComponent,
        RegistraMuestraMultipleComponent,
        ComprobanteMuestraComponent,
        CajaDeMuestrasComponent,
        CajaDeMuestrasIconComponent,
        ComprobanteCajaMuestraComponent,
        MatSpinnerOverlayComponent,
        MatModalConfirmComponent,
        NgModalConfirmComponent,
        MantComponentesComponent,
        SolicitudDeAnalisisComponent,
        PendientesFacturacionComponent,
        MantEquiposComponent,
        EquipoVerComponent,
        EquipoNuevoComponent,
        ComponenteNuevoComponent,
        ComponenteVerComponent,
        ComponenteAsignarEquipoComponent,
        ToastsContainer,
        CartaAutRetiroComponent,
        TarjetaComprobanteEnvioComponent,
        MantSolicitudDeAnalisisComponent,
        PopoverEditarTransporteComponent,
        ModalSolicitudAnalisisComponent,
        MantUsuariosComponent,
        UsuarioPerfilComponent,
        UsuarioNuevoComponent,
        CondicionesComercialesComponent,
        SolicitudDeInsumosComponent,
        ModalSolicitudDeInsumosComponent,
        MantMuestrasComponent,
        ModalGalleryComponent,
        PaginatorComponent,
        ExternalContentComponent,
        ReporteMenuComponent,
        ReporteHistoricoComponent,
        ModalReportesComponent,
        ModalSelectFaenaComponent,
        GetFaenasByClientIdPipe,
        ReporteComponentesComponent,
        RegistroModalComentarioComponent,
        HtmlTooltipDirective,
        ModalDocumentDetailComponent,
        SampleDetailComponent,
        EditSampleComponent,
        ModalEditSampleComponent,
        MoverComponentComponent
    ],
    imports: [
        NgbModule,
        NgSelectModule,
        BrowserModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        FontAwesomeModule,
        MomentModule,
        NgxKjuaModule,
        BasicLayoutModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatChipsModule,
        MatFormFieldModule,
        MatTabsModule,
        ProformaModule,
        ReportsModule,
        MatDatepickerModule,
        MatInputModule,
        MatNativeDateModule,
        MatMomentDateModule,
        MatTooltipModule,
        MatExpansionModule,
        MatCheckboxModule,
        // ServiceWorkerModule.register('ngsw-worker.js', {
        //     enabled: environment.production,
        //     registrationStrategy: 'registerWhenStable:30000'
        // }),
        BrowserAnimationsModule,
        LottieModule.forRoot({ player: playerFactory }),
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [environment.apiUrl],
                sendAccessToken: true,
            },
        })
    ],
    providers: [
        { provide: OAuthStorage, useClass: LocalStorageOAuthStorage },
        { provide: LOCALE_ID, useValue: 'es-*' },
        { provide: APP_BASE_HREF, useValue: '/' },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        // { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
        { provide: MatPaginatorIntl, useValue: CustomPaginator() },
        Storage,
        Tools,
        Pkce,
        StorageTools
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
